<template>
  <div class="signup-legal-person">
    <div class="signup-legal-form">
      <SignupForm>
        <div class="signup-form__group-item">
          <label class="signup-form__label" for="company-name">
            {{ $t('signup.labels.legal.company_name') }}
          </label>
          <TextField
            id="company-name"
            v-model.trim="companyName"
            name="company-name"
            required
            class="signup-form__input"
            type="text"
            :error="
              ($v.companyName.$dirty && !$v.companyName.required) ||
              ($v.companyName.$dirty && $v.companyName.$invalid)
            "
            :error-txt="companyNameErrorText"
            @isFocus="clearCompanyNameField"
            @blur="$v.companyName.$touch()"
          />
        </div>
        <div class="signup-form__group-item">
          <label class="signup-form__label" for="country-registration">
            {{ $t('signup.labels.legal.company_country_registration') }}
          </label>
          <SelectField
            v-model="countryRegistration"
            :items="countries"
            item-text="simple-name"
            append-to=".signup-legal-form .autocomplete-box"
            class="signup-form__input"
            :error="
              $v.countryRegistration.$dirty && !$v.countryRegistration.required
            "
            :error-txt="countryRegistrationErrorText"
          />
        </div>
        <div class="signup-form__group-item">
          <label class="signup-form__label" for="legal-address">
            {{ $t('signup.labels.legal.company_legal_address') }}
          </label>
          <TextField
            id="legal-address"
            v-model.trim="legalAddress"
            name="legal-address"
            required
            class="signup-form__input"
            type="text"
            :error="
              ($v.legalAddress.$dirty && !$v.legalAddress.required) ||
              ($v.legalAddress.$dirty && $v.legalAddress.$invalid)
            "
            :error-txt="legalAddressErrorText"
            @isFocus="clearLegalAddressField"
            @blur="$v.legalAddress.$touch()"
          />
        </div>
        <div class="signup-form__group-item">
          <label class="signup-form__label" for="edrpou-code">
            {{ $t('signup.labels.legal.company_edrpou_code') }}
          </label>
          <TextField
            id="edrpou-code"
            v-model.trim="edrpouCode"
            name="edrpou-code"
            required
            class="signup-form__input"
            type="text"
            :error="
              ($v.edrpouCode.$dirty && !$v.edrpouCode.required) ||
              ($v.edrpouCode.$dirty && $v.edrpouCode.$invalid)
            "
            :error-txt="edrpouCodeErrorText"
            @isFocus="clearEdrpouCodeField"
            @blur="$v.edrpouCode.$touch()"
          />
        </div>
        <div class="signup-form__group-item">
          <label class="signup-form__label" for="email">
            {{ $t('signup.labels.legal.company_email') }}
          </label>
          <TextField
            id="email"
            v-model.trim="companyEmail"
            type="email"
            name="email"
            required
            class="signup-form__input"
            :error="
              ($v.companyEmail.$dirty && !$v.companyEmail.required) ||
              ($v.companyEmail.$dirty && $v.companyEmail.$invalid)
            "
            :error-txt="companyEmailErrorText"
            @isFocus="clearCompanyEmailField"
            @blur="$v.companyEmail.$touch()"
          />
        </div>
        <div class="signup-form__group-item">
          <label class="signup-form__label" for="phone">
            {{ $t('signup.labels.legal.company_phone') }}
          </label>
          <TextField
            id="phone"
            v-model.trim="companyPhone"
            type="text"
            name="phone"
            required
            placeholder="+ __ ___ ___ __ __"
            class="signup-form__input"
            :error="
              ($v.companyPhone.$dirty && !$v.companyPhone.required) ||
              ($v.companyPhone.$dirty && $v.companyPhone.$invalid)
            "
            :error-txt="companyPhoneErrorText"
            @isFocus="clearCompanyPhoneField"
            @blur="$v.companyPhone.$touch()"
          />
        </div>
        <div class="signup-form__group-item">
          <label class="signup-form__label" for="password">
            {{ $t('signup.labels.legal.company_password') }}
          </label>
          <TextField
            id="password"
            v-model.trim="companyPassword"
            :type="passwordFieldType"
            name="password"
            required
            :error="
              ($v.companyPassword.$dirty && !$v.companyPassword.required) ||
              ($v.companyPassword.$dirty && $v.companyPassword.$invalid)
            "
            :error-txt="companyPasswordErrorText"
            is-exist-password-switcher
            :is-show-password="isShowPassword"
            @toggle-password="isShowPassword = !isShowPassword"
            @isFocus="clearCompanyPasswordField"
            @blur="$v.companyPassword.$touch()"
          />
        </div>
        <div class="signup-form__group-item">
          <label class="signup-form__label" for="repeat-password">
            {{ $t('signup.labels.legal.company_repeat_password') }}
          </label>
          <TextField
            id="repeat-password"
            v-model.trim="repeatCompanyPassword"
            :type="repeatPasswordFieldType"
            name="repeat-password"
            required
            :error="
              ($v.repeatCompanyPassword.$dirty &&
                !$v.repeatCompanyPassword.required) ||
              ($v.repeatCompanyPassword.$dirty &&
                $v.repeatCompanyPassword.$invalid)
            "
            :error-txt="repeatCompanyPasswordErrorText"
            is-exist-password-switcher
            :is-show-password="isShowRepeatPassword"
            @toggle-password="isShowRepeatPassword = !isShowRepeatPassword"
            @isFocus="clearRepeatCompanyPasswordField"
            @blur="$v.repeatCompanyPassword.$touch()"
          />
        </div>
        <div class="signup-form__group-item checkbox">
          <CheckboxField
            v-model="$v.companyAgreement.$model"
            :error="$v.companyAgreement.$dirty && !$v.companyAgreement.required"
            :error-txt="companyAgreementErrorText"
          />
        </div>
      </SignupForm>
    </div>
    <SignupControlButtons
      :back-button-text="$t('signup.button.back')"
      :go-button-text="$t('signup.button.continue')"
      @go-back="goBack"
      @go-on="goToNextStep"
    />
  </div>
</template>

<script>
import validate from '@/mixins/validation/signup/legalPerson';

import SignupControlButtons from '@/components/Signup/SignupControlButtons/SignupControlButtons.vue';
import SignupForm from '@/components/Signup/SignupForm.vue';
import TextField from '@/elements/Inputs/TextField.vue';
import SelectField from '@/elements/Inputs/SelectField.vue';
import CheckboxField from '@/elements/Inputs/CheckboxField.vue';

export default {
  components: {
    SignupControlButtons,
    SignupForm,
    TextField,
    SelectField,
    CheckboxField,
  },
  mixins: [validate],
  data() {
    return {
      companyName: '',
      countryRegistration: {
        id: 223,
        iso: 'UA',
        iso3: 'UKR',
        name: 'UKRAINE',
        'number-code': '804',
        'phone-code': '380',
        'simple-name': 'Ukraine',
      },
      countries: [],
      legalAddress: '',
      edrpouCode: '',
      companyEmail: '',
      companyPhone: '',
      companyPassword: '',
      repeatCompanyPassword: '',
      isShowPassword: false,
      isShowRepeatPassword: false,
      companyAgreement: false,
    };
  },
  computed: {
    passwordFieldType() {
      return this.isShowPassword ? 'text' : 'password';
    },
    repeatPasswordFieldType() {
      return this.isShowRepeatPassword ? 'text' : 'password';
    },
    companyInfo() {
      return {
        name_company: this.companyName,
        country_registration_id: this.countryRegistration.id,
        address: this.legalAddress,
        edrpou_code: this.edrpouCode,
        email_company: this.companyEmail,
        telephone_number_company: this.companyPhone,
        password: this.companyPassword,
      };
    },
  },
  created() {
    this.fetchAllCountries();
  },
  methods: {
    async fetchAllCountries() {
      const res = await this.$store.dispatch('getAllCountries');
      this.countries = res;
    },
    clearCompanyNameField() {
      this.$v.companyName.$reset();
    },
    clearLegalAddressField() {
      this.$v.legalAddress.$reset();
    },
    clearEdrpouCodeField() {
      this.$v.edrpouCode.$reset();
    },
    clearCompanyEmailField() {
      this.$v.companyEmail.$reset();
    },
    clearCompanyPhoneField() {
      this.$v.companyPhone.$reset();
    },
    clearCompanyPasswordField() {
      this.$v.companyPassword.$reset();
    },
    clearRepeatCompanyPasswordField() {
      this.$v.repeatCompanyPassword.$reset();
    },
    goBack() {
      this.$router.push({ name: 'Signup' });
    },
    goToNextStep() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.$store.commit('updateLegalPersonInfo', this.companyInfo);
      this.$router.push({ name: 'SignupLegalContactPerson' });
    },
  },
};
</script>

<style lang="sass">
.signup-legal-person
  padding-top: 48px

  @media screen and (max-width: 1199px)
    padding-top: 40px

  @media screen and (max-width: 600px)
    padding-top: 32px
</style>
