import {
  required,
  minLength,
  email,
  numeric,
  sameAs,
  helpers,
} from 'vuelidate/lib/validators';

import VueI18n from '@/i18n';

const phone = helpers.regex('number', /^\+[0-9]+$/);

const isCheckedAgreement = (value) => !!value;

export default {
  validations: {
    companyName: {
      required,
      minLength: minLength(2),
    },
    countryRegistration: {
      required,
    },
    legalAddress: {
      required,
      minLength: minLength(10),
    },
    edrpouCode: {
      required,
      numeric,
      minLength: minLength(8),
    },
    companyEmail: {
      required,
      email,
    },
    companyPhone: {
      required,
      numeric: phone,
      minLength: minLength(11),
    },
    companyPassword: {
      required,
      minLength: minLength(6),
    },
    repeatCompanyPassword: {
      required,
      sameAsPassword: sameAs('companyPassword'),
    },
    companyAgreement: {
      required: isCheckedAgreement,
    },
  },
  computed: {
    companyNameErrorText() {
      if (!this.$v.companyName.required) {
        return VueI18n.t('signup.legal.validation.company_name.empty');
      }
      if (!this.$v.companyName.minLength) {
        return VueI18n.t('signup.legal.validation.company_name.min_length');
      }
    },
    countryRegistrationErrorText() {
      if (!this.$v.countryRegistration.required) {
        return VueI18n.t(
          'signup.legal.validation.company_country_registration.empty'
        );
      }
    },
    legalAddressErrorText() {
      if (!this.$v.legalAddress.required) {
        return VueI18n.t('signup.legal.validation.company_legal_address.empty');
      }
      if (!this.$v.legalAddress.minLength) {
        return VueI18n.t(
          'signup.legal.validation.company_legal_address.min_length'
        );
      }
    },
    edrpouCodeErrorText() {
      if (!this.$v.edrpouCode.required) {
        return VueI18n.t('signup.legal.validation.company_edrpou_code.empty');
      }
      if (!this.$v.edrpouCode.numeric) {
        return VueI18n.t('signup.legal.validation.company_edrpou_code.numeric');
      }
      if (!this.$v.edrpouCode.minLength) {
        return VueI18n.t(
          'signup.legal.validation.company_edrpou_code.min_length'
        );
      }
    },
    companyEmailErrorText() {
      if (!this.$v.companyEmail.required) {
        return VueI18n.t('signup.legal.validation.company_email.empty');
      }
      if (!this.$v.companyEmail.email) {
        return VueI18n.t('signup.legal.validation.company_email.invalid');
      }
    },
    companyPhoneErrorText() {
      if (!this.$v.companyPhone.required) {
        return VueI18n.t('signup.legal.validation.company_phone.empty');
      }
      if (!this.$v.companyPhone.numeric) {
        return VueI18n.t('signup.legal.validation.company_phone.numeric');
      }
      if (!this.$v.companyPhone.minLength) {
        return VueI18n.t('signup.legal.validation.company_phone.min_length');
      }
    },
    companyPasswordErrorText() {
      if (!this.$v.companyPassword.required) {
        return VueI18n.t('signup.legal.validation.company_password.empty');
      }
      if (!this.$v.companyPassword.minLength) {
        return VueI18n.t('signup.legal.validation.company_password.min_length');
      }
    },
    repeatCompanyPasswordErrorText() {
      if (!this.$v.repeatCompanyPassword.required) {
        return VueI18n.t('signup.legal.validation.company_password.empty');
      }
      if (!this.$v.repeatCompanyPassword.sameAsPassword) {
        return VueI18n.t(
          'signup.legal.validation.company_password.not_same_as'
        );
      }
    },
    companyAgreementErrorText() {
      if (!this.$v.companyAgreement.required) {
        return VueI18n.t('signup.legal.validation.company_agreement.empty');
      }
    },
  },
};
