var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"signup-legal-person"},[_c('div',{staticClass:"signup-legal-form"},[_c('SignupForm',[_c('div',{staticClass:"signup-form__group-item"},[_c('label',{staticClass:"signup-form__label",attrs:{"for":"company-name"}},[_vm._v(" "+_vm._s(_vm.$t('signup.labels.legal.company_name'))+" ")]),_c('TextField',{staticClass:"signup-form__input",attrs:{"id":"company-name","name":"company-name","required":"","type":"text","error":(_vm.$v.companyName.$dirty && !_vm.$v.companyName.required) ||
            (_vm.$v.companyName.$dirty && _vm.$v.companyName.$invalid),"error-txt":_vm.companyNameErrorText},on:{"isFocus":_vm.clearCompanyNameField,"blur":function($event){return _vm.$v.companyName.$touch()}},model:{value:(_vm.companyName),callback:function ($$v) {_vm.companyName=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"companyName"}})],1),_c('div',{staticClass:"signup-form__group-item"},[_c('label',{staticClass:"signup-form__label",attrs:{"for":"country-registration"}},[_vm._v(" "+_vm._s(_vm.$t('signup.labels.legal.company_country_registration'))+" ")]),_c('SelectField',{staticClass:"signup-form__input",attrs:{"items":_vm.countries,"item-text":"simple-name","append-to":".signup-legal-form .autocomplete-box","error":_vm.$v.countryRegistration.$dirty && !_vm.$v.countryRegistration.required,"error-txt":_vm.countryRegistrationErrorText},model:{value:(_vm.countryRegistration),callback:function ($$v) {_vm.countryRegistration=$$v},expression:"countryRegistration"}})],1),_c('div',{staticClass:"signup-form__group-item"},[_c('label',{staticClass:"signup-form__label",attrs:{"for":"legal-address"}},[_vm._v(" "+_vm._s(_vm.$t('signup.labels.legal.company_legal_address'))+" ")]),_c('TextField',{staticClass:"signup-form__input",attrs:{"id":"legal-address","name":"legal-address","required":"","type":"text","error":(_vm.$v.legalAddress.$dirty && !_vm.$v.legalAddress.required) ||
            (_vm.$v.legalAddress.$dirty && _vm.$v.legalAddress.$invalid),"error-txt":_vm.legalAddressErrorText},on:{"isFocus":_vm.clearLegalAddressField,"blur":function($event){return _vm.$v.legalAddress.$touch()}},model:{value:(_vm.legalAddress),callback:function ($$v) {_vm.legalAddress=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"legalAddress"}})],1),_c('div',{staticClass:"signup-form__group-item"},[_c('label',{staticClass:"signup-form__label",attrs:{"for":"edrpou-code"}},[_vm._v(" "+_vm._s(_vm.$t('signup.labels.legal.company_edrpou_code'))+" ")]),_c('TextField',{staticClass:"signup-form__input",attrs:{"id":"edrpou-code","name":"edrpou-code","required":"","type":"text","error":(_vm.$v.edrpouCode.$dirty && !_vm.$v.edrpouCode.required) ||
            (_vm.$v.edrpouCode.$dirty && _vm.$v.edrpouCode.$invalid),"error-txt":_vm.edrpouCodeErrorText},on:{"isFocus":_vm.clearEdrpouCodeField,"blur":function($event){return _vm.$v.edrpouCode.$touch()}},model:{value:(_vm.edrpouCode),callback:function ($$v) {_vm.edrpouCode=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"edrpouCode"}})],1),_c('div',{staticClass:"signup-form__group-item"},[_c('label',{staticClass:"signup-form__label",attrs:{"for":"email"}},[_vm._v(" "+_vm._s(_vm.$t('signup.labels.legal.company_email'))+" ")]),_c('TextField',{staticClass:"signup-form__input",attrs:{"id":"email","type":"email","name":"email","required":"","error":(_vm.$v.companyEmail.$dirty && !_vm.$v.companyEmail.required) ||
            (_vm.$v.companyEmail.$dirty && _vm.$v.companyEmail.$invalid),"error-txt":_vm.companyEmailErrorText},on:{"isFocus":_vm.clearCompanyEmailField,"blur":function($event){return _vm.$v.companyEmail.$touch()}},model:{value:(_vm.companyEmail),callback:function ($$v) {_vm.companyEmail=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"companyEmail"}})],1),_c('div',{staticClass:"signup-form__group-item"},[_c('label',{staticClass:"signup-form__label",attrs:{"for":"phone"}},[_vm._v(" "+_vm._s(_vm.$t('signup.labels.legal.company_phone'))+" ")]),_c('TextField',{staticClass:"signup-form__input",attrs:{"id":"phone","type":"text","name":"phone","required":"","placeholder":"+ __ ___ ___ __ __","error":(_vm.$v.companyPhone.$dirty && !_vm.$v.companyPhone.required) ||
            (_vm.$v.companyPhone.$dirty && _vm.$v.companyPhone.$invalid),"error-txt":_vm.companyPhoneErrorText},on:{"isFocus":_vm.clearCompanyPhoneField,"blur":function($event){return _vm.$v.companyPhone.$touch()}},model:{value:(_vm.companyPhone),callback:function ($$v) {_vm.companyPhone=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"companyPhone"}})],1),_c('div',{staticClass:"signup-form__group-item"},[_c('label',{staticClass:"signup-form__label",attrs:{"for":"password"}},[_vm._v(" "+_vm._s(_vm.$t('signup.labels.legal.company_password'))+" ")]),_c('TextField',{attrs:{"id":"password","type":_vm.passwordFieldType,"name":"password","required":"","error":(_vm.$v.companyPassword.$dirty && !_vm.$v.companyPassword.required) ||
            (_vm.$v.companyPassword.$dirty && _vm.$v.companyPassword.$invalid),"error-txt":_vm.companyPasswordErrorText,"is-exist-password-switcher":"","is-show-password":_vm.isShowPassword},on:{"toggle-password":function($event){_vm.isShowPassword = !_vm.isShowPassword},"isFocus":_vm.clearCompanyPasswordField,"blur":function($event){return _vm.$v.companyPassword.$touch()}},model:{value:(_vm.companyPassword),callback:function ($$v) {_vm.companyPassword=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"companyPassword"}})],1),_c('div',{staticClass:"signup-form__group-item"},[_c('label',{staticClass:"signup-form__label",attrs:{"for":"repeat-password"}},[_vm._v(" "+_vm._s(_vm.$t('signup.labels.legal.company_repeat_password'))+" ")]),_c('TextField',{attrs:{"id":"repeat-password","type":_vm.repeatPasswordFieldType,"name":"repeat-password","required":"","error":(_vm.$v.repeatCompanyPassword.$dirty &&
              !_vm.$v.repeatCompanyPassword.required) ||
            (_vm.$v.repeatCompanyPassword.$dirty &&
              _vm.$v.repeatCompanyPassword.$invalid),"error-txt":_vm.repeatCompanyPasswordErrorText,"is-exist-password-switcher":"","is-show-password":_vm.isShowRepeatPassword},on:{"toggle-password":function($event){_vm.isShowRepeatPassword = !_vm.isShowRepeatPassword},"isFocus":_vm.clearRepeatCompanyPasswordField,"blur":function($event){return _vm.$v.repeatCompanyPassword.$touch()}},model:{value:(_vm.repeatCompanyPassword),callback:function ($$v) {_vm.repeatCompanyPassword=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"repeatCompanyPassword"}})],1),_c('div',{staticClass:"signup-form__group-item checkbox"},[_c('CheckboxField',{attrs:{"error":_vm.$v.companyAgreement.$dirty && !_vm.$v.companyAgreement.required,"error-txt":_vm.companyAgreementErrorText},model:{value:(_vm.$v.companyAgreement.$model),callback:function ($$v) {_vm.$set(_vm.$v.companyAgreement, "$model", $$v)},expression:"$v.companyAgreement.$model"}})],1)])],1),_c('SignupControlButtons',{attrs:{"back-button-text":_vm.$t('signup.button.back'),"go-button-text":_vm.$t('signup.button.continue')},on:{"go-back":_vm.goBack,"go-on":_vm.goToNextStep}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }